import { groq } from "next-sanity";

export const pageQuery = (pageName: string) => groq`
*[_type in ["${pageName}"]] {
    ...
  }
`;

export const settingsQuery = groq`
  *[_type in ["siteSettings"]] {
    header,
    footer {
      footerImage {
        alt,
        "url": asset->url,
        "width": asset->metadata.dimensions.width,
        "height": asset->metadata.dimensions.height
      },
      footerText,
      footerLinks
    },
    defaultVariables,
    modalFreeTextInput,
    modalRating,
    emailScheduling,
    repetitionUnlock,
    languageList
  }
`;

export const coursesPageQuery = groq`
*[_type in ["coursesPage"]] {
  ...,
  backgroundImage {
    "url": asset->url,
    "width": asset->metadata.dimensions.width,
    "height": asset->metadata.dimensions.height
  },
  menuLayers[] {
    ...,
    "image": image.asset->url,
    "imageDesktop": imageDesktop.asset->url,
  },
  explaination {
    explainText,
    "iconTypes": [
        {"icon": iconFinished.icon, "text": iconFinished.text},
        {"icon": iconLocked.icon, "text": iconLocked.text},
        {"icon": iconOngoing.icon, "text": iconOngoing.text}
    ]
  }
}
`;

export const menteesPageQuery = groq`
  *[_type in ["menteesPage"]] {
    mainPageTitle,
    mainPageDescription,
    noEmployeesMainPageDescription,
    explaination,
    navButtons,
    guide {
      guideTitle,
      guideDescription,
      accordionSections[]{
        "id": _key,
        header,
        items[]{
          "id": _key,
          header,
          content
        }
      }
    },
    modulesSection {
      moduleTitle,
      moduleDescription,
      explaination {
        explainText,
        "iconTypes": [
            {"icon": iconFinished.icon, "text": iconFinished.text},
            {"icon": iconLocked.icon, "text": iconLocked.text},
            {"icon": iconOngoing.icon, "text": iconOngoing.text}
        ]
      },
      btnColumns,
    },
    modal
  }
`;
