import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useState
} from "react";

import { AccordionContext } from "./accordion";
import styles from "./accordion.module.scss";
export interface AccordionItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Content in Accordion.Item
   * Should include one Accordion.Header and one Accordion.Content
   */
  children: React.ReactNode;
  /**
   * Controlled open-state
   * Using this removes automatic control of open-state
   */
  open?: boolean;
  /**
   * Defaults the accordion to open if not controlled
   * @default false
   */
  defaultOpen?: boolean;
  id: string;
}

export type AccordionItemType = React.ForwardRefExoticComponent<
  AccordionItemProps & React.RefAttributes<HTMLDivElement>
>;

export interface AccordionItemContextProps {
  open: boolean;
  toggleOpen: () => void;
}

export const AccordionItemContext =
  createContext<AccordionItemContextProps | null>(null);

const AccordionItem: AccordionItemType = forwardRef(
  ({ children, open, defaultOpen = false, id }, ref) => {
    const accordionContext = useContext(AccordionContext);
    open = accordionContext?.open === id ? true : false;

    const [internalOpen, setInternalOpen] = useState<boolean>(defaultOpen);

    return (
      <div className={styles["item"]} ref={ref} id={id}>
        <AccordionItemContext.Provider
          value={{
            open: open ?? internalOpen,
            toggleOpen: () => {
              if (open === undefined) {
                setInternalOpen((iOpen) => !iOpen);
              }
              if (open) {
                accordionContext?.toggleOpen("");
              } else {
                accordionContext?.toggleOpen(id);
              }
            }
          }}
        >
          {children}
        </AccordionItemContext.Provider>
      </div>
    );
  }
);

export default AccordionItem;
