// eslint-disable-next-line no-unused-vars
import classNames from "classnames";
import React, {
  BaseSyntheticEvent,
  ButtonHTMLAttributes,
  ReactNode
} from "react";

import styles from "./button.module.scss";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  autoFocus?: boolean;
  disabled?: boolean;
  ariaDisabled?: boolean;
  ariaLabel?: string;
  ariaDescribedby?: string;
  ariaControls?: string;
  ariaExpanded?: boolean;
  form?: string;
  name?: string;
  type?: "button" | "reset" | "submit";
  /**
   * The default styles for buttons
   */
  variant?:
    | "primary"
    | "secondary"
    | "ghost"
    | "header"
    | "link"
    | "aside"
    | "headerClose"
    | "accordion";
  innerVariant?: "negative";
  size?: "xsmall" | "small" | "medium" | "large" | "full" | "long";
  value?: string;
  onClick?: any;
  style?: any;
  innerStyle?: any;
  rtl?: boolean;
  id?: string;
  attribute?: string;

  /**
   * All content - text, image etc.
   */
  children?: ReactNode;

  /** If supplied, triggers audio on click. */
  soundClickSRC?: string;

  /** If supplied, triggers SFX on click ("wrong", "correct" etc). */
  soundClickType?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      autoFocus = false,
      disabled = false,
      ariaDisabled,
      ariaDescribedby,
      ariaControls,
      ariaExpanded,
      name,
      type = "button",
      variant = "primary",
      innerVariant = "",
      size = "medium",
      value,
      children,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick = () => {},
      style,
      innerStyle,
      ariaLabel,
      rtl = false,
      soundClickSRC = "",
      soundClickType = "",
      id,
      tabIndex,
      attribute = "button"
    },
    ref
  ) => {
    const className = classNames(styles["btn"], {
      [styles["primary"]]: variant === "primary",
      [styles["secondary"]]: variant === "secondary",
      [styles["ghost"]]: variant === "ghost",
      [styles["header"]]: variant === "header",
      [styles["headerClose"]]: variant === "headerClose",
      [styles["accordion"]]: variant === "accordion",
      [styles["link"]]: variant === "link",
      [styles["aside"]]: variant === "aside",
      [styles["xsmall"]]: size === "xsmall",
      [styles["small"]]: size === "small",
      [styles["medium"]]: size === "medium",
      [styles["large"]]: size === "large",
      [styles["full"]]: size === "full",
      [styles["long"]]: size === "long",
      [styles["flex"]]: children && typeof children === "object",
      [styles["rtl"]]: rtl === true,
      [styles["open"]]: ariaExpanded === true
    });

    const innerClassName = classNames(styles["innerBtn"], {
      [styles["negative"]]: innerVariant === "negative"
    });

    /**
     * Triggers if user just clicked.
     * @param event Click event, forwarded to prop if it exists.
     */
    function HandleClick(event: BaseSyntheticEvent) {
      //sound?

      //custom event callback?
      if (onClick !== undefined) onClick(event);
    }

    if (typeof window !== "undefined") {
      if ("ontouchstart" in document.documentElement) {
        const buttons = Array.from(
          document.querySelectorAll(`.${styles["btn"]}`)
        );
        const headerBtns = Array.from(
          document.querySelectorAll(`.${styles["header"]}`)
        );
        buttons.forEach((button) => button.classList.add(styles["nohover"]));
        headerBtns.forEach((button) => button.classList.add(styles["nohover"]));
      }
    }

    /**
     * Applies dangerouslySetInnerHTML if raw string contains &shy;, else keeps children intact.
     * @returns React DOM-element.
     */
    const checkShy = () => {
      let foundDangerously = false;
      const shyString = children?.toString() ?? "";

      if (shyString.indexOf("&shy;") > 0) {
        foundDangerously = true;
        //console.info("Text: checkShy=",shyString+", foundDangerously="+foundDangerously);
      }

      if (foundDangerously) {
        return (
          <div
            className={innerClassName}
            style={innerStyle}
            dangerouslySetInnerHTML={{ __html: shyString }}
          ></div>
        );
      } else {
        return (
          <div className={innerClassName} style={innerStyle}>
            {children}
          </div>
        );
      }
    };

    return (
      <button
        id={id}
        ref={ref}
        className={className}
        aria-describedby={ariaDescribedby}
        aria-label={ariaLabel}
        aria-controls={ariaControls}
        aria-expanded={ariaExpanded}
        aria-disabled={ariaDisabled}
        disabled={disabled ?? false}
        name={name}
        type={type}
        value={value}
        onClick={HandleClick}
        style={style}
        tabIndex={tabIndex}
        data-type={attribute}
      >
        {checkShy()}
      </button>
    );
  }
);

export default Button;
