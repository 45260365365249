/* eslint-disable @typescript-eslint/ban-types */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";

export enum ModalContentType {
  /** Standard text box with title and body (should be default). */
  TEXT = "text",

  /** Rating box (1-x). */
  NUMBER = "number",

  /** Input box (feedback). */
  TEXTINPUT = "textinput"
}
export interface ModalProps {
  modalHeadline?: string;
  modalText: string;
  modalBtnCancel?: string;
  modalBtnSubmit?: string;
  modalBtnClose?: string;
  toggleModal: Function;
  submitModal: Function;
  exitBtn?: boolean;
  contentBtn?: boolean;
  dualBtn?: boolean;
  bottomBtn?: boolean;
  bottomBtnLabel?: string;
  dualBtnCancel?: string;
  dualBtnSubmit?: string;
  submitBtnStyle?: Record<string, unknown>;
  submitBtnInnerStyle?: Record<string, unknown>;
  bottomBtnStyle?: Record<string, unknown>;
  bottomBtnInnerStyle?: Record<string, unknown>;
  cancelBtnStyle?: Record<string, unknown>;
  cancelBtnInnerStyle?: Record<string, unknown>;
  sizeButton?: "small" | "medium" | "large";
  courseNumber?: number;
  courseTotal?: number;
  completedText?: string;
  modalType?: ModalContentType;
  setting?: any;
  linkBottom?: boolean;
  linkBottomLabel?: string;

  /** Callback function to send value to, for instance courses SetFeedbackByIndex(value, 0). */
  storeValueCallback?: Function;
}
