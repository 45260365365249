import { useEffect, useState } from "react";

import { TypeCourseData } from "../utils/TypeCourseData";

interface UseCourseModalReturn {
  modalPopUp: boolean;
  selectedCourse: TypeCourseData | null;
  openCourseModal: (course: TypeCourseData) => void;
  clearSelectedCourse: () => void;
  toggleModal: () => void;
  closeModal: () => void;
}

const useCourseModal = (): UseCourseModalReturn => {
  const [modalPopUp, setModalPopUp] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<TypeCourseData | null>(
    null
  );

  const openCourseModal = (course: TypeCourseData): void => {
    setSelectedCourse(course);
    setModalPopUp(true);
  };

  const clearSelectedCourse = (): void => {
    setSelectedCourse(null);
  };

  const toggleModal = (): void => {
    setModalPopUp(!modalPopUp);
  };

  const closeModal = (): void => {
    setModalPopUp(false);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (modalPopUp && event.key === "Escape") {
        toggleModal();
      }
    };

    if (typeof document !== "undefined") {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (typeof document !== "undefined") {
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [modalPopUp, toggleModal]);

  return {
    modalPopUp,
    selectedCourse,
    openCourseModal,
    clearSelectedCourse,
    toggleModal,
    closeModal
  };
};

export default useCourseModal;
