import Exmark from "assets/images/exmark.svg";
import React, { FunctionComponent } from "react";

import styles from "./infoMessage.module.scss";

interface InfoProps {
  children: any;
}

export const InfoMessage: FunctionComponent<InfoProps> = ({ children }) => {
  return (
    <div className={styles["infoMessageBox"]}>
      <span className={styles["icon"]}>
        <Exmark />
      </span>
      <div className={styles["message"]}>{children}</div>
    </div>
  );
};

export default InfoMessage;
