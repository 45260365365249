/**
 * Generic alert message object for warnings, info etc.
 */
export type AlertMessage = {
  /** Title/header of info message. */
  alertTitle: string;

  /** Body/text of info message. */
  alertBody: string;

  /** Type of info message. */
  alertType: AlertType;

  /** Field of the error message, optional */
  errorInputIds?: string[];
};

/**
 * Generic alert types.
 */
export enum AlertType {
  ERROR = "alerterror",
  SUCCESS = "alertsuccess",
  WARNING = "alertwarning",
  INFO = "alertinfo"
}
