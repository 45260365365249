import classNames from "classnames";
import React, { forwardRef, useContext, useEffect, useRef } from "react";

import styles from "./accordion.module.scss";
//import AnimateHeight from "../util/AnimateHeight";
//import { BodyLong } from "../typography/BodyLong";
import { AccordionItemContext } from "./accordion-item";
export interface AccordionContentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Content inside Accordion.Content
   */
  children: React.ReactNode;
}

export type AccordionContentType = React.ForwardRefExoticComponent<
  AccordionContentProps & React.RefAttributes<HTMLDivElement>
>;

const AccordionContent: AccordionContentType = forwardRef(
  ({ children, className, id, ...rest }, ref) => {
    const context = useContext(AccordionItemContext);

    if (context === null) {
      console.error(
        "<Accordion.Content> has to be used within an <Accordion.Item>"
      );
      return null;
    }

    const contentClass = classNames(styles["content"], {
      [styles["open"]]: context.open,
      [styles["close"]]: !context.open
    });

    let runningTimeoutAutofocus: number;
    const refContainer = useRef<HTMLDivElement>(null);

    /**
     * setTimeout for a delayed function/method call. Clears running.
     * @param delayCallback Function to trigger delayed. No args. Name only (MySuperFunction).
     * @param delayMs Delay in milliseconds.
     * @param clearTimeoutID  Timeout to clear, for instance same variable (number) used initiallyto clear itself and avoid multiple running timeouts.
     */
    function DelayedCallback(
      delayCallback: Function,
      delayMs: number,
      clearTimeoutID: number
    ) {
      let newTimeout = 0;

      if (clearTimeoutID !== null) clearTimeout(clearTimeoutID);
      newTimeout = setTimeout(delayCallback, delayMs);

      return newTimeout;
    }

    /**
     * Sets focus to starting element of active context.
     * By doing this delayed (after render) to an element with tabindex="0" NVDA for instance will start talking DOM-elements within that box.
     * This will require custom adjustments per template.
     */
    function FocusTTS() {
      if (refContainer.current !== null) {
        // console.log("rating.FocusTTS");
        refContainer.current.focus({
          preventScroll: true
        });
      }
    }

    function DelayedFocusTTS() {
      //issue delayed focus for first element (nvda)
      clearTimeout(runningTimeoutAutofocus);
      runningTimeoutAutofocus = DelayedCallback(
        FocusTTS,
        200,
        runningTimeoutAutofocus
      );
    }

    /**
     * Cleanup!
     */
    function Release() {
      clearTimeout(runningTimeoutAutofocus);
    }

    //only trigger once, and handle gc/release
    useEffect(() => {
      if (context.open) {
        DelayedFocusTTS();
      }

      return () => Release();
    });

    return (
      <div ref={refContainer} tabIndex={-1} id={id} className={contentClass}>
        <div ref={ref}>{children}</div>
      </div>
    );
  }
);

export default AccordionContent;
