import { Button, Text } from "@taskalliance/lms";
import {
  BaseSyntheticEvent,
  FormEventHandler,
  FunctionComponent,
  useRef,
  useState
} from "react";

import styles from "./freeTextInput.module.scss";

export interface FreeTextInputProps {
  textareaLabel?: string;
  submitLabel?: string;
  instructionText?: string;
  textareaRemainText?: string;
  maxNumberCharacters?: number;
  textareaTooMuchText?: string;
  /** Callback function to send value to, for instance courses SetFeedbackByIndex(value, 0). */
  submitInput?: any;
}

export const FreeTextInput: FunctionComponent<FreeTextInputProps> = ({
  textareaLabel,
  submitLabel,
  instructionText,
  textareaRemainText = "{{x}} characters left",
  maxNumberCharacters = 100,
  textareaTooMuchText = "{{x}} characters too much",
  submitInput
}) => {
  const [charsInfo, setCharsInfo] = useState<string>(
    textareaRemainText.replace("{{x}}", maxNumberCharacters.toString())
  );
  const [disableBtn, setDisableBtn] = useState<boolean>(true);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const instructionTextRef = useRef<HTMLParagraphElement>(null);

  const checkText = () => {
    if (textareaRef.current !== null) {
      const countLeft = maxNumberCharacters - textareaRef.current.value.length;
      const countTooMuch =
        textareaRef.current.value.length - maxNumberCharacters;
      const countertext = document.getElementsByClassName(
        styles["characterCounter"]
      );
      if (textareaRef.current.value.length > 0) {
        setDisableBtn(false);
        if (maxNumberCharacters >= textareaRef.current.value.length) {
          countertext[0].classList.remove(styles["characterCounterError"]);
          setCharsInfo(
            textareaRemainText.replace("{{x}}", countLeft.toString())
          );
        } else {
          countertext[0].classList.add(styles["characterCounterError"]);
          setCharsInfo(
            textareaTooMuchText.replace("{{x}}", countTooMuch.toString())
          );
          setDisableBtn(true);
        }
      } else {
        setDisableBtn(true);
        setCharsInfo(textareaRemainText.replace("{{x}}", countLeft.toString()));
      }
    }
  };

  /**
   * Local submit handler, sending data down the submitInput callback(*:any).
   */
  const handleSubmit = (submitEvent: BaseSyntheticEvent) => {
    const newValue = textareaRef.current?.value;
    submitEvent.preventDefault();

    if (submitInput && textareaRef.current !== null) {
      submitInput(newValue);
    } else {
      console.warn("No submitInput or textareaRef?");
    }
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["textInputContainer"]} tabIndex={-1}>
        <div className={styles["innerContainer"]}>
          <form onSubmit={handleSubmit}>
            <label htmlFor="inputText">{textareaLabel}</label>
            <div className={styles["textareaWrapper"]}>
              <textarea
                aria-labelledby="title"
                ref={textareaRef}
                onChange={checkText}
                name="inputText"
                id="inputText"
                tabIndex={0}
              />
              <Text as="p" customClassName={styles["characterCounter"]}>
                {charsInfo}
              </Text>
            </div>

            <Text
              ref={instructionTextRef}
              as="p"
              customClassName={styles["instruction"]}
            >
              {instructionText}
            </Text>
            <div className={styles["buttonContainer"]}>
              <Button
                size="medium"
                variant="primary"
                disabled={disableBtn}
                type="submit"
              >
                {submitLabel}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FreeTextInput;
