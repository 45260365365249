import React, { createContext, forwardRef, useState } from "react";

import styles from "./accordion.module.scss";
import AccordionContent, { AccordionContentType } from "./accordion-content";
import AccordionHeader, { AccordionHeaderType } from "./accordion-header";
import AccordionItem, { AccordionItemType } from "./accordion-item";

interface AccordionComponent
  extends React.ForwardRefExoticComponent<
    AccordionProps & React.RefAttributes<HTMLDivElement>
  > {
  Item: AccordionItemType;
  Header: AccordionHeaderType;
  Content: AccordionContentType;
}

export interface AccordionContextProps {
  open: string;
  toggleOpen: (id: string) => void;
}

export const AccordionContext = createContext<AccordionContextProps | null>(
  null
);

export interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  /**
   * Controlled open-state
   * Using this removes automatic control of open-state
   */
  open?: boolean;
}

export const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  ({ ...rest }, ref) => {
    const [openItem, setOpenItem] = useState<string>("bob");

    return (
      <AccordionContext.Provider
        value={{
          open: openItem,
          toggleOpen: (id) => {
            setOpenItem(id);
          },
        }}
      >
        <div className={styles["accordion"]} {...rest} ref={ref} />
      </AccordionContext.Provider>
    );
  }
) as AccordionComponent;

Accordion.Header = AccordionHeader;
Accordion.Content = AccordionContent;
Accordion.Item = AccordionItem;

export default Accordion;
