import { useUsers } from "@hooks/api";
import IconGlobe from "assets/images/icon_language_default.svg";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import styles from "./languageSelector.module.scss";

interface LanguageSelectorProps {
  languages: string[]; // Array of language codes (e.g., ["En", "Fr"])
  href: string; //for example: /auth/profile
  withIcon?: boolean;
  customClassName?: string;
  onSelectLanguage?: (language: string) => void;
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  languages,
  href,
  withIcon = true,
  customClassName,
  onSelectLanguage
}) => {
  const router = useRouter();
  const { user } = useUsers();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<any>(router.locale);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLanguage = (language: string) => {
    setSelectedLanguage(language);
    onSelectLanguage ? onSelectLanguage(language) : null;
  };

  useEffect(() => {
    selectLanguage(
      router.locale.split("")[0].toUpperCase() + router.locale.substring(1)
    );
  }, [router.locale]);

  useEffect(() => {
    withIcon ? setIsOpen(false) : setIsOpen(true);
  }, [withIcon, selectedLanguage]);

  useEffect(() => {
    const f = async () => {
      if (selectedLanguage.toLowerCase() !== router.locale) {
        languages.find((language) => {
          if (language.toLowerCase() === router.locale) {
            setSelectedLanguage(language);
          }
        });
      }
    };
    f();
  }, [router]);

  useEffect(() => {
    if (user) {
      languages.some((language) => {
        language.toLowerCase() === user?.language?.toLowerCase()
          ? setSelectedLanguage(language)
          : null;
      });
    } else {
      languages.find((language) => {
        if (language.toLowerCase() === router.locale) {
          setSelectedLanguage(language);
        }
      });
    }
  }, [user]);

  return (
    <div className={styles.language_selector}>
      {isOpen && (
        <div className={`${styles.language_options} ${customClassName}`}>
          {languages.map((language, index) => (
            <div key={index} className={styles.language_option}>
              <div
                className={`${styles.language_option} ${
                  withIcon && language === selectedLanguage
                    ? styles.selected
                    : !withIcon && language === selectedLanguage
                    ? styles.selectedNoIcon
                    : ""
                }`}
              >
                <Link
                  href={href ?? "/"}
                  locale={language.toLowerCase()}
                  style={{ textDecoration: "none", fontWeight: "normal" }}
                >
                  {language}
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
      {withIcon && (
        <button
          className={styles.language_icon}
          onClick={toggleDropdown}
          data-type="language-selector"
        >
          <div className={styles.textandicon}>
            <a>{!isOpen && selectedLanguage}</a>
            {isOpen ? (
              <div className={styles["isOpen"]}>
                <IconGlobe />
              </div>
            ) : (
              <IconGlobe />
            )}
          </div>
        </button>
      )}
    </div>
  );
};

export default LanguageSelector;
