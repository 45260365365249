import { Button, Text } from "@taskalliance/lms";
import CollapseIcon from "assets/images/icon_collapse.svg";
import ExpandIcon from "assets/images/icon_expand.svg";
import React, { forwardRef, useContext } from "react";

//import { AccordionContext } from "./accordion";
import styles from "./accordion.module.scss";
import { AccordionItemContext } from "./accordion-item";

export interface AccordionHeaderProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  contentId: string;
  /**
   * Text inside Accordion.Header
   */
  children: React.ReactNode;
}

export type AccordionHeaderType = React.ForwardRefExoticComponent<
  AccordionHeaderProps & React.RefAttributes<HTMLButtonElement>
>;

const AccordionHeader: AccordionHeaderType = forwardRef(
  ({ children, contentId, className, onClick, ...rest }, ref) => {
    //const accordionContext = useContext(AccordionContext);
    const itemContext = useContext(AccordionItemContext);

    if (itemContext === null) {
      console.error(
        "<Accordion.Header> has to be used within an <Accordion.Item>"
      );
      return null;
    }

    const handleClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      //accordionContext?.toggleOpen();
      itemContext.toggleOpen();
      onClick && onClick(e);
    };

    return (
      <div className={styles["header"]} aria-expanded={itemContext.open}>
        <Button
          ref={ref}
          type="button"
          variant="accordion"
          size="full"
          onClick={handleClick}
          ariaExpanded={itemContext.open}
          ariaControls={contentId}
          attribute="accordion"
        >
          <Text size="small" as="span" customClassName={styles["buttonTitle"]}>
            {children}
          </Text>
          {itemContext.open ? <CollapseIcon /> : <ExpandIcon />}
        </Button>
      </div>
    );
  }
);

export default AccordionHeader;
