import { FunctionComponent, HTMLAttributes } from "react";

import styles from "./spinner.module.scss";

export interface SpinnerProps extends HTMLAttributes<HTMLElement> {
  color?: string;
  emptyColor?: string;
  size?: string;
  time?: string;
  thickness?: string;
}

export const Spinner: FunctionComponent<SpinnerProps> = ({
  color = "#292e28",
  emptyColor = "#f1f1f1",
  size = "30px",
  time = "1s",
  thickness = "4px"
}) => {
  if (typeof window !== "undefined") {
    const styleSheet = document.styleSheets[0];

    const keyframes = `@keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }`;

    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }

  const spinnerStyles = {
    border: `${thickness} solid ${emptyColor}`,
    borderTop: `${thickness} solid ${color}`,
    borderRadius: "50%",
    width: size,
    height: size,
    animation: `spin ${time} linear infinite`
  };

  return (
    <div id="spinner" className={styles["loader"]} style={spinnerStyles}></div>
  );
};

export default Spinner;
