import Exmark from "assets/images/exmark.svg";
import React, { FunctionComponent } from "react";

import styles from "./successMessage.module.scss";

interface SuccessProps {
  children: any;
}

export const SuccessMessage: FunctionComponent<SuccessProps> = ({
  children
}) => {
  return (
    <div className={styles["successMessageBox"]}>
      <span className={styles["icon"]}>
        <Exmark />
      </span>
      <div className={styles["message"]}>{children}</div>
    </div>
  );
};

export default SuccessMessage;
