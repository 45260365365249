import Exmark from "assets/images/exmark.svg";
import React, { FunctionComponent } from "react";

import styles from "./errorMessage.module.scss";

interface ErrorProps {
  children: any;
}

export const ErrorMessage: FunctionComponent<ErrorProps> = ({ children }) => {
  return (
    <div className={styles["errorMessageBox"]}>
      <span className={styles["icon"]}>
        <Exmark />
      </span>
      <div className={styles["message"]}>{children}</div>
    </div>
  );
};

export default ErrorMessage;
