import { useAuth } from "@hooks/api";
import { Button } from "@taskalliance/lms";
import IconProfile from "assets/images/icon_profile.svg";
import IconExit from "assets/images/icon-exit.svg";
import { useRouter } from "next/router";
import { FunctionComponent, useRef } from "react";

import styles from "./aside.module.scss";

import IconClose from "assets/images/menu-close.svg";

interface AsideProps {
  asideData: any;
  showProfileButton?: boolean;
}

export const Aside: FunctionComponent<AsideProps> = ({
  asideData,
  showProfileButton
}) => {
  const { logout } = useAuth();
  const router = useRouter();

  const asideMenu = useRef<HTMLElement>(null);

  const closeMenu = () => {
    asideMenu.current?.classList.remove("open");
  };

  const logOff = () => {
    logout();
    router.push("/");
  };

  const goToProfile = () => {
    closeMenu();
    router.push("/auth/profile");
  };

  if (typeof document !== "undefined") {
    document.onkeydown = (event: KeyboardEvent) => {
      let isEscape = false;
      if ("key" in event) {
        isEscape =
          event.key === "Escape" ||
          event.key === "Esc" ||
          event.code === "Escape";
      }

      if (isEscape) {
        closeMenu();
      }
    };
  }

  //   const handleClickOutside = (e: any) => {
  //     if (asideMenu?.current?.contains(e.target)) {
  //       closeMenu();
  //     }
  //   };

  //   useEffect(() => {
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => document.removeEventListener("mousedown", handleClickOutside);
  //   });

  return (
    <aside ref={asideMenu}>
      <nav>
        <p style={{ textAlign: "right", marginRight: "-3rem" }}>
          <Button
            variant={"headerClose"}
            attribute="menu-close"
            onClick={closeMenu}
            tabIndex={0}
          >
            <IconClose />
          </Button>
        </p>
        {showProfileButton && (
          <Button variant="aside" attribute="profile" onClick={goToProfile}>
            <IconProfile />
            {asideData?.profileLabel}
          </Button>
        )}
        <Button variant="aside" attribute="logout" onClick={logOff}>
          <IconExit />
          {asideData?.logOutLabel}
        </Button>
        <hr
          style={{
            margin: "0.5rem 0",
            display: "var(--aside-display-hr)"
          }}
        />
      </nav>
    </aside>
  );
};

export default Aside;
