import { Button, Text } from "@taskalliance/lms";
import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState
} from "react";

import styles from "./rating.module.scss";
import { RatingProps } from "./ratingProps";

export const Rating: FunctionComponent<RatingProps> = ({
  numbersOfRating,
  instructionText,
  submitLabel,
  submitRate
}) => {
  const [value, setValue] = useState<string>("");
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const [submittedInput, setSubmittedInput] = useState<HTMLInputElement>();

  let runningTimeoutAutofocus: number;
  const refContainer = useRef<HTMLDivElement>(null);

  const getValue = (event: ChangeEvent<HTMLInputElement>) => {
    const parent = event.target.parentElement;
    const grandParent = parent?.parentElement;

    // Let's see if you understand this later
    const eventCheckedValue = !event.target.checked;

    if (parent && grandParent) {
      for (const element of grandParent.children) {
        const input: HTMLInputElement | null = element.querySelector(
          "input[type='checkbox']"
        );
        if (input !== null) {
          input.checked = false;
        }
      }
      const selectedInput: HTMLInputElement | null = parent.querySelector(
        "input[type='checkbox']"
      );

      if (selectedInput !== null) {
        selectedInput.checked = !eventCheckedValue;

        if (selectedInput.checked) {
          setDisabledSubmit(false);
          setValue(event.target.value);
          setSubmittedInput(selectedInput);
        } else {
          setDisabledSubmit(true);
          setValue("");
          setSubmittedInput(undefined);
        }
      }
    }
  };

  const rateValues = [];
  for (let i = 1; i <= numbersOfRating; i++) {
    rateValues.push(
      <div className={styles["inputValues"]} key={i}>
        <input
          type="checkbox"
          name="answer"
          id={i.toString()}
          value={i.toString()}
          onChange={getValue}
        />
        <label htmlFor={i.toString()}>{i}</label>
      </div>
    );
  }

  /**
   * Local submit handler, sending data down the submitRate callback(*:any).
   */
  const handleSubmit = () => {
    const newValue = value;

    if (submitRate) {
      console.log("Value to be saved:", value);
      if (submittedInput !== undefined) {
        submittedInput.checked = false;
      }
      setValue("");
      setDisabledSubmit(true);
      submitRate(newValue);
    } else {
      console.warn("No submitRate?");
    }
  };

  /**
   * setTimeout for a delayed function/method call. Clears running.
   * @param delayCallback Function to trigger delayed. No args. Name only (MySuperFunction).
   * @param delayMs Delay in milliseconds.
   * @param clearTimeoutID  Timeout to clear, for instance same variable (number) used initiallyto clear itself and avoid multiple running timeouts.
   */
  function DelayedCallback(
    delayCallback: Function,
    delayMs: number,
    clearTimeoutID: number
  ) {
    let newTimeout = 0;

    if (clearTimeoutID !== null) clearTimeout(clearTimeoutID);
    newTimeout = setTimeout(delayCallback, delayMs);

    return newTimeout;
  }

  /**
   * Sets focus to starting element of active context.
   * By doing this delayed (after render) to an element with tabindex="0" NVDA for instance will start talking DOM-elements within that box.
   * This will require custom adjustments per template.
   */
  function FocusTTS() {
    if (refContainer.current !== null) {
      //console.log("rating.FocusTTS");
      refContainer.current.focus({
        preventScroll: true
      });
    }
  }

  function DelayedFocusTTS() {
    //issue delayed focus for first element (nvda)
    clearTimeout(runningTimeoutAutofocus);
    runningTimeoutAutofocus = DelayedCallback(
      FocusTTS,
      200,
      runningTimeoutAutofocus
    );
  }

  /**
   * Cleanup!
   */
  function Release() {
    clearTimeout(runningTimeoutAutofocus);
  }

  //only trigger once, and handle gc/release
  useEffect(() => {
    //DelayedFocusTTS;

    return () => Release();
  });

  return (
    <div className={styles["container"]}>
      <div
        className={styles["ratingContainer"]}
        ref={refContainer}
        tabIndex={-1}
      >
        <>
          {/*inline trigger focus to work around consecutive not triggering useeffect*/}
          {/*DelayedFocusTTS()*/}
        </>
        <div className={styles["valueTextContainer"]}>
          <div className={styles["ratingValues"]}>{rateValues}</div>
          <div className={styles["instructionContainer"]}>
            <Text as="p">{instructionText}</Text>
          </div>
          <div className={styles["buttonContainer"]}>
            <Button
              variant="secondary"
              size="medium"
              type="submit"
              disabled={disabledSubmit}
              onClick={handleSubmit}
            >
              {submitLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rating;
