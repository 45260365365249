import {
  CourseStatus,
  LearningpathUserStatus,
  ScheduleEmailsObject,
  TypeCourseData,
  UnlockCourseMethod
} from "@utils/TypeCourseData";
import LZString from "lz-string";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { LearningPathRepetition } from "../lms";
import { useAuth, useCourses, useEmailSending, useLMS, useUsers } from "./api";

/**
 * Note: if problems importing this, put it at the bottom of the imports (import order matters).
 */
const useUserCourses = ({
  forwardDiplomaToMentor = false,
  scheduleEmails,
  unlockCourseMethod = UnlockCourseMethod.UNLOCK_ALL_AFTER_FIRST_COMPLETED,
  hasFeedback = false,
  hasRepetition = true,
  hasMultipleLanguages = false
}: {
  forwardDiplomaToMentor?: boolean;
  scheduleEmails?: ScheduleEmailsObject;
  unlockCourseMethod?: UnlockCourseMethod;
  hasFeedback?: boolean;
  hasRepetition?: boolean;
  hasMultipleLanguages?: boolean;
}) => {
  const { courses } = useCourses();
  const router = useRouter();
  const { userId } = useAuth();
  const { scheduleEmail } = useEmailSending();
  const { getUserLearningpathWithStatuses, getUserCourses, getUser } =
    useUsers();
  const {
    getCourseUserStatus,
    forwardDiploma,
    getDiplomasSent,
    getLearningpaths
  } = useLMS();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [parsedCourses, setParsedCourses] = useState(Array<TypeCourseData>);
  const [userLearningpathStatus, setUserLearningpathStatus] =
    useState<LearningpathUserStatus>();
  const [repUserLearningpathStatus, setRepUserLearningpathStatus] =
    useState<LearningpathUserStatus>();

  /**
   * Parses incoming coursedata into local structure.
   */
  async function CourseParseData(
    courseData: any,
    learningPathCourses: any
  ): Promise<TypeCourseData[]> {
    const courseList: Array<TypeCourseData> = [];

    let correctCourses: any;
    // Sort the learning path courses by their order and find correct language
    if (hasMultipleLanguages && router.locale !== router.defaultLocale) {
      correctCourses = learningPathCourses?.map((lpCourse: any) => {
        return courses?.data?.find((course: any) => {
          if (course.attributes.locale === router.locale) {
            return course.attributes?.courseId === lpCourse.id;
          }
        });
      });
    } else {
      correctCourses = learningPathCourses?.map((lpCourse: any) => {
        return courses?.data?.find((course: any) => course.id === lpCourse.id);
      });
    }

    for (let i = 0; i < correctCourses?.length; i++) {
      const courseId = correctCourses[i].id;
      const learningPathCourseId = learningPathCourses[i].id;

      // Find the corresponding course in courseData
      const courseIndex = courseData.data.findIndex(
        (course: any) => course.id === learningPathCourseId
      );

      const newCourseData: TypeCourseData = new TypeCourseData();
      const foundCourse = courses.data.find(
        (course: any) => course.id === courseId
      );

      //parse course data into local structure
      newCourseData.courseId = String(courseData.data[courseIndex].id);
      newCourseData.type = String(courseData.data[courseIndex].type);
      newCourseData.completedDate = String(
        courseData.data[courseIndex].meta.completedDate
      );
      newCourseData.attempts = courseData.data[courseIndex].meta.attempts;

      newCourseData.title = String(foundCourse.attributes.title);
      newCourseData.locale = String(foundCourse.attributes.locale);
      newCourseData.launchURL = String(foundCourse.attributes.launch?.url);
      newCourseData.description = String(foundCourse.attributes.description);
      newCourseData.link = String(foundCourse.links.self);

      //defaults
      newCourseData.unlockTimeMilliseconds = 0;
      newCourseData.hidden = false;
      newCourseData.courseCompleted = undefined;
      newCourseData.courseTotal = undefined;

      //completion status of last known attempt
      if (newCourseData.attempts.length > 0) {
        const foundStatus =
          newCourseData.attempts[newCourseData.attempts.length - 1].status;

        //always get lastupdated, regardless of completion status
        newCourseData.lastUpdated =
          newCourseData.attempts[newCourseData.attempts.length - 1].updatedAt;

        //here we're assuming the last attempt is always the most recent one, regardless of date
        //even if this isn't found, this will (and should!) get updated during lms commits
        newCourseData.mostRecentAttemptID =
          newCourseData.attempts[newCourseData.attempts.length - 1].id;

        //decompress and parse suspend_data using same attempt if found, and parse it
        if (
          newCourseData.attempts[newCourseData.attempts.length - 1].data !=
            null &&
          newCourseData.attempts[newCourseData.attempts.length - 1].data[
            "cmi.suspend_data"
          ] != undefined
        ) {
          const decompressedSuspendData = JSON.parse(
            LZString.decompressFromBase64(
              newCourseData.attempts[newCourseData.attempts.length - 1].data[
                "cmi.suspend_data"
              ]
            )
          );
          newCourseData.mostRecentSuspendData = decompressedSuspendData;

          //make note of completed and total
          if (decompressedSuspendData?.i !== null) {
            newCourseData.courseTotal = decompressedSuspendData?.m.length;
          }
          if (decompressedSuspendData?.m !== null) {
            newCourseData.courseCompleted = decompressedSuspendData?.m.filter(
              (module) => module.s == CourseStatus.COMPLETED
            ).length;
          }
        }

        if (foundStatus.toLowerCase() === CourseStatus.COMPLETED) {
          newCourseData.status = CourseStatus.COMPLETED;
        } else {
          newCourseData.status = CourseStatus.INCOMPLETE;
        }
      } else {
        newCourseData.status = CourseStatus.NOTATTEMPTED;
        newCourseData.lastUpdated = "";
      }

      if (hasFeedback) {
        //course feedback/evaluation from server
        newCourseData.feedback = foundCourse.attributes?.meta?.feedback;
      }

      courseList.push(newCourseData);
    }

    //only first one unlocked, unlock all after first one completed
    if (courseList[0].status !== CourseStatus.COMPLETED) {
      if (courseList[0].status === CourseStatus.INCOMPLETE) {
        courseList[0].currentGoToItemId = courseList[0].courseId;
      }
      //start on the second one in array
      for (let i = 1; i < courseList.length; i++) {
        if (courseList[i].status !== CourseStatus.COMPLETED) {
          courseList[i].status = CourseStatus.LOCKED;
          courseList[i].currentGoToItemId = "";
        }
      } //find the last updated attempt and add currentGoToItem to that
    } else {
      let mostRecentIndex = 1;
      for (let i = 2; i < courseList.length; i++) {
        if (
          courseList[i].status !== CourseStatus.COMPLETED &&
          courseList[i]?.lastUpdated > courseList[mostRecentIndex]?.lastUpdated
        ) {
          mostRecentIndex = i;
        }
      }
      courseList.forEach((dateObj, index) => {
        if (index === mostRecentIndex) {
          dateObj.currentGoToItemId = dateObj.courseId;
        } else {
          dateObj.currentGoToItemId = "";
        }
      });
    }

    if (unlockCourseMethod === UnlockCourseMethod.UNLOCK_ONE_AT_A_TIME) {
      if (courseList[0] && courseList[0].status === CourseStatus.COMPLETED) {
        for (let i = 1; i < courseList.length - 1; i++) {
          if (courseList[i].status !== CourseStatus.COMPLETED) {
            courseList[i + 1].status = CourseStatus.LOCKED;
          }
        }
      }
    }

    return courseList;
  }

  /**
   * Detect amount of courses that, at this time, is unlocked.
   * Anything with hidden=true is not considered unlocked.
   * @param courseList List of parsed courses (parsedCourses for instance)
   * @returns 1-based count.
   */
  function DetectAmountUnlockedCourses(courseList: TypeCourseData[]): number {
    let foundUnlocked = 0;
    const currentTime = Date.now();

    for (let i = 0; i < courseList.length; i++) {
      if (
        courseList[i].unlockTimeMilliseconds === 0 ||
        currentTime >= courseList[i].unlockTimeMilliseconds
      ) {
        if (courseList[i].hidden === false) foundUnlocked++;
      }
    }
    return foundUnlocked;
  }

  /**
   * Fetches courses for user to parse into menu structure and render.
   * Cannot depend on cacheing for this to work reliably.
   * This includes repetition modules, which get defined as such to differentiate them from the primary learning path.
   * @todo  In total there's at least 3 + (amount courses) calls to DB, all sync/await. That ain't ideal.
   */
  async function GetParsedUserCourses(
    newLearningPathID: string,
    repetitionTypes?: Array<LearningPathRepetition>
  ): Promise<TypeCourseData[]> {
    //start showing modal loading, this could take a few seconds...
    setIsLoading(true);

    const currentUserCourses = await getUserCourses(userId);
    const learningPathStatus = await getUserLearningpathWithStatuses({
      userId,
      learningpathId: newLearningPathID
    });
    setUserLearningpathStatus(learningPathStatus);

    /** List of parsed course data for user, which we can use to render the menu. */
    let courseList: Array<TypeCourseData> = [];

    //first the primary learning path
    courseList = await CourseParseData(
      currentUserCourses,
      learningPathStatus.meta.courses
    );

    //apply feedback answers to each courseid in courselist
    if (hasFeedback) {
      await AddFeedbackToCourses(courseList);
    }
    if (hasRepetition && repetitionTypes) {
      courseList = await AddRepetition(
        learningPathStatus,
        courseList,
        currentUserCourses,
        repetitionTypes
      );
    }

    //console.log("GetUserCoures...courses=", courses,", currentUserCourses=",currentUserCourses,", learningpathStatus=",learningpathStatus);

    //end showing modal loading
    setIsLoading(false);

    /*console.log(
      "GetParsedUserCourses: courseList=",
      courseList,
      ", repetitionTypes=",
      repetitionTypes
    );*/
    return courseList;
  }

  async function AddRepetition(
    learningPathStatus: any,
    courseList: TypeCourseData[],
    currentUserCourses: any,
    repetitionTypes: LearningPathRepetition[]
  ) {
    let repetitionStartTime = new Date(
      learningPathStatus.learningpath_status.date
    ).getTime();
    //next check for/parse repetition modules and if any, append to list regardless of unlock time
    //override if nan or path not yet completed to always hide...
    if (
      isNaN(repetitionStartTime) ||
      learningPathStatus.learningpath_status.status !== "COMPLETE"
    )
      repetitionStartTime = -1;

    courseList = courseList.concat(
      await ParseRepetitionCourses(
        currentUserCourses,
        repetitionStartTime,
        repetitionTypes,
        learningPathStatus
      )
    );
    /*console.log(
      "GetParsedUserCourses: courseList=",
      courseList,
      ", repetitionTypes=",
      repetitionTypes
    );*/
    return courseList;
  }

  async function AddFeedbackToCourses(courseList: TypeCourseData[]) {
    const courseStatusPromises = [];
    for (let i = 0; i < courseList.length; i++) {
      courseStatusPromises.push(
        getCourseUserStatus(userId, courseList[i].courseId)
      );
    }
    try {
      const newCourseStatuses = await Promise.all(courseStatusPromises);
      for (let i = 0; i < newCourseStatuses.length; i++) {
        const newCourseStatus = newCourseStatuses[i];
        if (newCourseStatus.data != null && newCourseStatus.data.meta != null) {
          for (let j = 0; j < newCourseStatus.data.meta.feedback.length; j++) {
            const foundFeedback: object = newCourseStatus.data.meta.feedback[j];

            //copy value to zero-based index of 1-based id
            courseList[i].feedback[foundFeedback["id"] - 1].userAnswer =
              foundFeedback["answer"];
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Check if its time to add rep modules to courseList
   * Parses repetition modules, sets unlock timestamps, and returns new array of TypeCourseData which can be appended.
   * @param currentUserCourses  getUserCourses object.
   * @param lpCompletedMilliseconds Primary learning path completed date in milliseconds. Used to set unlock time/millis for repetition modules. If this is -1, we force hidden=true.
   * @param repetitionTypes  Sanity array of {repID:string, repOffsetMonths:number, repOffsetDays:number}. This should ideally be slightly relaxed values.
   * @returns Parsed repetition courses.
   * @todo  Move this out.
   */
  async function ParseRepetitionCourses(
    currentUserCourses,
    lpCompletedMilliseconds,
    repetitionTypes: Array<LearningPathRepetition>,
    learningPathStatus
  ) {
    const learningPaths = await getLearningpaths();
    const repLearningPaths = learningPaths.data.filter(
      (learningPath: any) => learningPath.attributes?.meta?.type === "rep"
    );
    let newParsedRepetitionStatuses = [];

    //get status for each repetition learningpath, parse it
    for (let i = 0; i < repLearningPaths.length; i++) {
      const newStatus = await getUserLearningpathWithStatuses({
        userId,
        learningpathId: repLearningPaths[i].id
      });

      setRepUserLearningpathStatus(newStatus);

      //parse it
      const newCourseList = await CourseParseData(
        currentUserCourses,
        newStatus.meta.courses
      );

      //mark as repetition by applying an unlock timestamp based off lpCompletedMilliseconds
      //if this is invalid, or 0, we assume we'll default them to hidden instead
      for (let j = 0; j < repetitionTypes.length; j++) {
        if (
          repLearningPaths[i].attributes.meta.shortname.no ===
          repetitionTypes[j].unlockID
        ) {
          //offset completed time with repetition type detected
          //note that offsetting months past its bounds increases year for instance (12 months as 0-based input is allowed, adding one year and 1 month)
          //also note that ideally (in a practical world) we'll set the unlock hour and seconds of the day to the start of the day to incresase chances of triggering event
          const newTime = new Date(lpCompletedMilliseconds);

          //manually force 00:00 to mark beginning of the day if months and days are set to 0
          //this way, someone might have completed 1600 and have something unlocked at the beginning of "next unlock day"
          if (
            repetitionTypes[j].unlockOffsetMonths !== 0 &&
            repetitionTypes[j].unlockOffsetDays !== 0
          ) {
            newTime.setHours(0, 0, 0, 0);
          }

          newTime.setMonth(
            newTime.getMonth() + repetitionTypes[j].unlockOffsetMonths
          );
          newTime.setDate(
            newTime.getDate() + repetitionTypes[j].unlockOffsetDays
          );
          newTime.setHours(
            newTime.getHours() + repetitionTypes[j].unlockOffsetHours
          );
          newTime.setMinutes(
            newTime.getMinutes() + repetitionTypes[j].unlockOffsetMinutes
          );

          newCourseList.forEach((item) => {
            if (
              learningPathStatus?.learningpath_status?.status === "COMPLETE" &&
              newStatus?.learningpath_status?.status === "COMPLETE"
            ) {
              item.hidden = false;
            }
            //fetch month value, and convert into milliseconds, then offset with input
            else if (lpCompletedMilliseconds === -1) {
              item.hidden = true;
            } else {
              item.unlockTimeMilliseconds = newTime.getTime();
              console.log(
                repetitionTypes[j].unlockID +
                  "=" +
                  new Date(item.unlockTimeMilliseconds)
              );
            }
          });
        }
      }

      newParsedRepetitionStatuses =
        newParsedRepetitionStatuses.concat(newCourseList);
    }

    return newParsedRepetitionStatuses;
  }

  /**
   * Trigger diploma send from server to user email.
   * Typically we'd trigger this once all courses have been completed.
   */
  async function CheckDiplomaSend(
    userObject: any,
    currentCourseList: TypeCourseData[]
  ) {
    const foundAllCompleted = currentCourseList
      .filter((item) => item.unlockTimeMilliseconds === 0)
      .every((course) => course.status === CourseStatus.COMPLETED);

    if (!foundAllCompleted || userObject === null) return;

    const diplomasSent = await getDiplomasSent({ userId });

    /*console.log(
      "CheckDiplomaSend: currentCourseList=",
      currentCourseList,
      ", diplomasSent=",
      diplomasSent,
      ", foundAllCompleted=",
      foundAllCompleted,
      ", diplomasSent.length=",
      diplomasSent.length
    );*/

    //console.log("user=", user);

    if (diplomasSent.data.length <= 0) {
      //first send diploma to user
      try {
        /*console.log(
          "CheckDiplomaSend: 1/2 all detected as completed, issue senddiploma to user! userId=" +
            userId +
            ", learningpathId=" +
            userObject.userLearningpath.id +
            ", to (mentor)=" +
            userObject.email
        );*/
        await forwardDiploma({
          userId,
          learningpathId: userObject.userLearningpath.id,
          to: userObject.email,
          finished: true
        });

        if (scheduleEmails) {
          scheduleEmails.scheduleEmailInfo.forEach((element) => {
            ScheduleEmails(userId, element.months, element.typeOfEmail);
          });
        }
        //testing purposes
        //await scheduleEmail(userId, 100, "repetition3");
        //await scheduleEmail(userId, 3700, "repetition6");

        if (forwardDiplomaToMentor) {
          ForwardDiplomaToMentor(userObject);
        }
      } catch (error) {
        console.warn("courses.CheckDiplomaSend forwardDiploma failed");
      }
    } else if (foundAllCompleted === true) {
      //console.log("All completed, and already issued diploma: ignoring");
    }
  }

  async function ScheduleEmails(
    userId: string,
    futureMonths: number,
    typeOfEmail: string
  ) {
    //this is the real
    await scheduleEmail(
      userId,
      calculateFutureSeconds(futureMonths),
      typeOfEmail
    );
    //testing purposes
    //await scheduleEmail(userId, 100, "repetition3");
    //await scheduleEmail(userId, 3700, "repetition6");
  }

  async function ForwardDiplomaToMentor(userObject: any) {
    const forwardUserId =
      userObject.meta.hasMentor !== "MANGLER MENTOR"
        ? userObject.meta.hasMentor
        : userObject.styrerId ?? null;

    if (forwardUserId !== null) {
      const userForward = await getUser(forwardUserId);
      const forwardEmail = userForward.data.attributes.email;
      //then send finished to mentor (finished: false)
      /*console.log(
        "CheckDiplomaSend: 2/2 all detected as completed, issue senddiploma to mentor! userId=" +
          userId +
          ", learningpathId=" +
          userObject.userLearningpath.id +
          ", to (mentor/styrer)=" +
          forwardEmail +
          ", userMentor=",
        userForward
      );*/
      await forwardDiploma({
        userId,
        learningpathId: userObject.userLearningpath.id,
        to: forwardEmail,
        finished: false
      });

      if (scheduleEmails && scheduleEmails.mentorScheduleEmailInfo.length > 0) {
        scheduleEmails.mentorScheduleEmailInfo.forEach((element) => {
          ScheduleEmails(forwardUserId, element.months, element.typeOfEmail);
        });
        //testing purposes
        //await scheduleEmail(forwardUserId, 100, "repetitionAdded");
        //await scheduleEmail(forwardUserId, 3700, "repetitionAdded");
      }
    }
  }

  function calculateFutureSeconds(numberOfMonths: number): number {
    const currentDate = new Date(); // Get the current date and time
    const futureDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + numberOfMonths,
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    );

    const currentTimestamp = currentDate.getTime();
    const futureTimestamp = futureDate.getTime();

    const secondsDifference = Math.floor(
      (futureTimestamp - currentTimestamp) / 1000
    );

    return secondsDifference;
  }

  useEffect(() => {
    //return function cleanup() {};
  }, []);

  return {
    TypeCourseData,
    CourseParseData,
    CourseStatus,
    DetectAmountUnlockedCourses,
    GetParsedUserCourses,
    ParseRepetitionCourses,
    isLoading,
    setIsLoading,
    parsedCourses,
    setParsedCourses,
    CheckDiplomaSend,
    userLearningpathStatus,
    repUserLearningpathStatus
  };
};

export default useUserCourses;
