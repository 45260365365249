import { useCallback, useEffect, useState } from "react";

import { scormAPI } from "../utils/scormAPI";
import {
  TypeCourseData,
  TypeCourseDataAttempts
} from "../utils/TypeCourseData";
import { useAuth, useLMS } from "./api";

const useSCORM = () => {
  const [state, setState] = useState();
  const [totalTime, setTotalTime] = useState(0);
  const [activeAttempt, setActiveAttempt] = useState(null);
  const { createAttempt, getAttempt, updateAttempt } = useLMS();
  const { userId } = useAuth();
  const callback = useCallback((e: any) => {
    setState(e);
  }, []);

  /**
   * Default initial SCORM state.
   */
  const NEW_EMPTY_ATTEMPT = {
    "cmi.core.lesson_location": "",
    "cmi.core.lesson_status": "not attempted",
    "cmi.core.lesson_mode": "normal",
    "cmi.suspend_data": "",
    "cmi.launch_data": "",
    "cmi.core.session_time": "0000:00:00",
    "cmi.core.total_time": "0000:00:00"
  };

  const convertCMITimespanToSeconds = (cmiTime = "0000:00:00") => {
    const seconds = Number(cmiTime.split(":")[2]);
    const minutes = Number(cmiTime.split(":")[1]);
    const hours = Number(cmiTime.split(":")[0]);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const convertSecondsToCMITimespan = (time = 0) => {
    let remainder = 0;
    const hours = (time - (time % 3600)) / 3600;
    remainder = time % 3600;
    const minutes = (remainder - (remainder % 60)) / 60;
    const seconds = remainder % 60;
    const cmiTime = `${String(hours).padStart(4, "0")}:${String(
      minutes
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`; //"0000:00:00";
    return cmiTime;
  };

  /**
   * Initialize SCORM attempt.
   * Needs serious tweaking.
   */
  async function InitializeSCORM(selectedCourse: TypeCourseData) {
    let attemptObject;
    let lastAttempt: TypeCourseDataAttempts;

    //start from scratch, or continue?
    if (selectedCourse.attempts.length === 0) {
      /*       console.log(
        "--------> First attempt, createAttempt: userId=" +
          userId +
          ", courseid=" +
          selectedCourse.courseId
      ); */
      attemptObject = await createAttempt(userId, selectedCourse.courseId);
      scormAPI(JSON.parse(JSON.stringify(NEW_EMPTY_ATTEMPT)));
      setActiveAttempt(attemptObject.data);
      setTotalTime(0);
    } else {
      //continue, in this case never restart or create another attempt but make use of the one we have
      lastAttempt = selectedCourse.attempts[selectedCourse.attempts.length - 1];
      attemptObject = await getAttempt(lastAttempt.id);
      //console.log("initializeSCORM 1: attemptObject=", attemptObject);

      if (attemptObject.data?.attributes?.data != null) {
        //continue/resume
        //console.log("--------> RESUME FROM EXISTING");
        scormAPI(attemptObject.data?.attributes?.data);
        if (
          attemptObject.data?.attributes?.data["cmi.core.total_time"] != null
        ) {
          setTotalTime(
            convertCMITimespanToSeconds(
              attemptObject.data?.attributes?.data["cmi.core.total_time"]
            )
          );
        } else {
          setTotalTime(0);
        }
        setActiveAttempt(attemptObject.data);
      } else {
        /*  console.log(
          "--------> ATTEMPTS MADE BUT NO DATA? -> NEW_EMPTY_ATTEMPT"
        ); */
        attemptObject.data.attributes.data = {};
        scormAPI(JSON.parse(JSON.stringify(NEW_EMPTY_ATTEMPT)));
        setActiveAttempt(attemptObject.data);
        setTotalTime(0);
      }
    }
  }

  /**
   * Commit SCORM-data in-flight.
   * Note uppercase to avoid runtime errors/failed commits.
   * @todo  Ideally needs a rewrite -> standardized logger.
   */
  function SCORMCommit({ detail }) {
    if (!activeAttempt) {
      return;
    }
    if (detail.data == null) {
      return;
    }

    // oppdater total tid, basert på opprinnelig total tid og nåværende session tid
    const session_CMITime = detail.data["cmi.core.session_time"];
    const new_total_seconds =
      totalTime + convertCMITimespanToSeconds(session_CMITime);
    const new_total_CMITime = convertSecondsToCMITimespan(new_total_seconds);
    let status = "";
    let result = null;

    //console.log("SCORMCommit: activeAttempt=", activeAttempt);

    /*if (activeAttempt?.attributes?.status) {
      status = activeAttempt.attributes.status.toUpperCase();
    }*/

    //update from live detail input instead of relying on activeattempt
    status = detail.data["cmi.core.lesson_status"].toUpperCase();

    //passed or not?
    if (activeAttempt?.attributes?.result) {
      result = activeAttempt.attributes.result.toUpperCase();
    }

    // hvis tidligere fullført, sett lesson_status='completed' uansett
    //commented out for now, shouldn't be relying on non-course data
    /*if (
      String(activeAttempt.attributes.status).toLowerCase() ===
      CourseStatus.COMPLETED
    ) {
      detail.data["cmi.core.lesson_status"] =
        CourseStatus.COMPLETED.toUpperCase();
      status = CourseStatus.COMPLETED.toUpperCase();
      result = CourseStatus.PASSED.toUpperCase();
    }*/

    //need to set completedDate: issue passed if completed to do so
    if (status == "COMPLETED") result = "PASSED";

    // oppdatert attemptet
    setActiveAttempt((activeAttempt) => {
      return {
        ...activeAttempt,
        attributes: {
          ...activeAttempt.attributes,
          data: { ...detail.data, "cmi.core.total_time": new_total_CMITime },
          status: status,
          result: result,
          totalTime: String(new_total_seconds)
        }
      };
    });
  }

  /**
   * Commits (saves/applies) user course attempt object to LMS.
   * @param currentAttempt
   */
  async function UpdateAttempt(currentAttempt: any) {
    if (currentAttempt != null) {
      if (currentAttempt.id && currentAttempt.attributes) {
        //console.log("useSCORM.UpdateAttempt: currentAttempt=", currentAttempt);
        await updateAttempt(currentAttempt.id, currentAttempt.attributes);
      }
    }
  }

  useEffect(() => {
    document?.addEventListener("Commit", callback);
    return function cleanup() {
      document?.removeEventListener("Commit", callback);
    };
  }, []);

  /**
   * SCORM logger commit on change.
   */
  useEffect(() => {
    if (!state) {
      return;
    }

    SCORMCommit(state);
  }, [state]);

  return {
    state,
    NEW_EMPTY_ATTEMPT,
    convertCMITimespanToSeconds,
    convertSecondsToCMITimespan,
    totalTime,
    setTotalTime,
    activeAttempt,
    setActiveAttempt,
    InitializeSCORM,
    SCORMCommit,
    UpdateAttempt
  };
};

export default useSCORM;
