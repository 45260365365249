import { Text } from "@taskalliance/lms";
import classNames from "classnames";
import { FunctionComponent } from "react";

import styles from "./notifications.module.scss";

interface NotificationsProps {
  notificationText?: string;
}

export const Notifications: FunctionComponent<NotificationsProps> = ({
  notificationText
}) => {
  /*
    const notifications = useAppSelector((state) => state.notifications);
  const variant = notifications.notification?.variant || 'counter';
  const content = notifications.notification?.content || null;
  */

  const notificationClassNames = classNames(styles["notifications-content"], {
    //[styles['counter']]: variant === 'counter',
    //[styles['title']]: variant === 'title',
    //[styles['streak']]: variant === 'streak',
    //[styles['hidden']]: !content,
  });

  return (
    <div className={styles["notifications"]}>
      <div className={styles["notifications-background"]}></div>
      <div className={styles["notifications-foreground"]}>
        <div className={notificationClassNames}>
          <Text as="span" forceDangerouslySetInnerHTML={true}>
            {notificationText}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
