export const scormAPI = (cmiData) => {
  //console.log("scormAPI 1.2:", cmiData);
  window["API"] = (function () {
    let data = cmiData;
    return {
      LMSInitialize: function (str = "") {
        return "true";
      },
      LMSCommit: function () {
        // persist data
        const customEvent = new CustomEvent("Commit", {
          detail: {
            data: data
          }
        });
        document.dispatchEvent(customEvent);
        return "true";
      },
      LMSFinish: function () {
        // persist data and close communication

        //todo: old code left intact for now
        /*const customEvent = new CustomEvent("Finish", {
          data: data
        });*/

        //packed inside detail object to avoid build error
        const customEvent = new CustomEvent("Finish", {
          detail: {
            data: data
          }
        });
        document.dispatchEvent(customEvent);
        return "true";
      },
      LMSGetValue: function (model) {
        return data[model] || "";
      },
      LMSSetValue: function (model, value) {
        data[model] = value;
        //this.LMSCommit();
        return "true";
      },
      LMSGetLastError: function () {
        return "0";
      },
      LMSGetErrorString: function (errorCode) {
        return "No error";
      },
      LMSGetDiagnostic: function (errorCode) {
        return "No error";
      }
    };
  })();
};
