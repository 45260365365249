import { useState } from "react";

import { AlertMessage, AlertType } from "./alertUtils";

//move to hooks?
export function useAlert(): [
  AlertMessage | undefined,
  (
    newTitle: string,
    newBody: string,
    newType: AlertType,
    errorInputIds?: string[]
  ) => void,
  () => void
] {
  const [alertMessage, setAlertMessage] = useState<AlertMessage>();

  /**
   * Clears alert message.
   */
  function clearAlert() {
    setAlertMessage({
      alertTitle: "",
      alertBody: "",
      alertType: AlertType.ERROR
    });
  }

  /**
   * Sets new alert message (error, info etc).
   * @param newTitle Optional title of error.
   * @param newBody Body/main text.
   * @param newType AlertType.* (ERROR).
   * @param errorInputIds id of the input field to mark it, optional
   */
  function setAlert(
    newTitle: string,
    newBody: string,
    newType: AlertType,
    errorInputIds?: string[]
  ) {
    setAlertMessage({
      alertTitle: newTitle,
      alertBody: newBody,
      alertType: newType,
      errorInputIds: errorInputIds
    });
  }

  return [alertMessage, setAlert, clearAlert];
}
