import { Text } from "@taskalliance/lms";
import Image from "next/image";
import Link from "next/link";
import React, { FunctionComponent } from "react";

import styles from "./footer.module.scss";

interface footerProps {
  footerData: any;
}

export const Footer: FunctionComponent<footerProps> = ({ footerData }) => {
  return (
    <footer className={styles["footer"]}>
      <div className={styles["containerLogo"]}>
        {footerData && footerData?.footerImage.url && (
          <Image
            src={footerData?.footerImage.url}
            width="140"
            height="40"
            alt={footerData?.footerImage.alt}
          />
        )}
      </div>
      <div>
        <div className={styles["containerText"]}>
          <Text
            as="p"
            size="small"
            style={{ fontWeight: "var(--font-weight-semibold)" }}
          >
            {footerData?.footerText}
          </Text>
        </div>
        {footerData?.footerLinks && footerData?.footerLinks.length > 0 && (
          <div className={styles["containerLinks"]}>
            <Text as="p" size="small">
              {footerData?.footerLinks.map(
                (
                  footerLink: {
                    _key: string;
                    href: string;
                    linkText: string;
                    blank: boolean;
                  },
                  index: number
                ) => (
                  <React.Fragment key={footerLink._key}>
                    <Link
                      href={footerLink.href}
                      target={footerLink.blank ? "_blank" : "_self"}
                    >
                      {footerLink.linkText}
                    </Link>
                    {index !== footerData?.footerLinks.length - 1 ? " | " : ""}
                  </React.Fragment>
                )
              )}
            </Text>
          </div>
        )}
      </div>
      <div style={{ minWidth: "9rem" }}></div>
    </footer>
  );
};

export default Footer;
