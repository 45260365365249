import { Button, Notifications } from "@taskalliance/lms";
import BackIcon from "assets/images/icon_back.svg";
import MenuOpenIcon from "assets/images/menu-open.svg";
import LanguageSelector from "libs/lms/src/lib/components/languageSelector/languageSelector";
import Link from "next/link";
import { useRouter } from "next/router";

import Aside from "../aside/aside";
import { HeaderMode } from "./header.mode";
import styles from "./header.module.scss";
import { HeaderProps } from "./header.props";

export const Header: React.FunctionComponent<HeaderProps> = ({
  mode = HeaderMode.PUBLIC,
  headerData,
  showBackButton = false,
  headerToggleProfile = false,
  notificationText,
  languages = false,
  hrefLanguages
}) => {
  /**
   * TODO:
   * switch between public and authenticated
   */
  const router = useRouter();

  const goBack = () => {
    router.back();
  };

  const openMenu = () => {
    document.querySelector("header > aside")?.classList.add("open");
  };

  if (mode == HeaderMode.AUTHENTICATED) {
    return (
      <header className={styles.headerAuthenticated}>
        <div className={styles["top-row"]}>
          <div className={styles["leftContainer"]}>
            {showBackButton && (
              <Button
                id="btnBack"
                type={"button"}
                variant={"header"}
                ariaLabel={"Back"}
                attribute="go-back"
                onClick={goBack}
              >
                <BackIcon />
              </Button>
            )}
          </div>
          <div className={styles["rightContainer"]}>
            <Button
              id="btnOpen"
              type={"button"}
              variant={"header"}
              ariaLabel={"Open side menu"}
              attribute="open-menu"
              onClick={openMenu}
            >
              <MenuOpenIcon />
            </Button>
          </div>
        </div>
        {notificationText && (
          <Notifications notificationText={notificationText} />
        )}
        <Aside asideData={headerData} showProfileButton={headerToggleProfile} />
      </header>
    );
  } else {
    return (
      <header className={styles.headerPublic}>
        <div className={styles.linkAndLanguage}>
          <Link href="/">{headerData?.headerTitle}</Link>
          {languages && (
            <LanguageSelector
              languages={["En", "Fr", "De", "It"]}
              href={hrefLanguages}
            />
          )}
        </div>
      </header>
    );
  }
};

export default Header;
