export enum FeedbackMode {
  /** Closed, not being used (default). If a mode is anything but this, we're assuming we shold be rendering feedback modal. */
  CLOSED = "closed",

  /** Introduction/first page. */
  INTRO = "intro",

  /** One question/exercise of a type (text/input, number/rating). */
  QUESTION = "question",

  /** Summary/end page. */
  SUMMARY = "summary"
}
