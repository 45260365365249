import React, { FunctionComponent } from "react";

import styles from "./dropdown.module.scss";

interface DropdownProps {
  id: string;
  name: string;
  onChange: any;
  options: Array<any>;
  defaultOption?: string;
  chosenOption?: string;
}

export const Dropdown: FunctionComponent<DropdownProps> = ({
  id,
  name,
  onChange,
  options,
  defaultOption,
  chosenOption
}) => {
  return (
    <div className={styles.dropdown}>
      <select onChange={onChange} id={id} name={name} key={id}>
        {defaultOption && (
          <option value="" disabled selected>
            {defaultOption}
          </option>
        )}
        {chosenOption && <option value={chosenOption}>{chosenOption}</option>}
        {options.map(
          (option) =>
            chosenOption !== option.title && (
              <option key={option.id} value={option.id}>
                {option.title}
              </option>
            )
        )}
      </select>
    </div>
  );
};

export default Dropdown;
