import { Button, FreeTextInput, Rating, Text } from "@taskalliance/lms";
import IconClose from "assets/images/menu-close-modal.svg";
import Link from "next/link";
import { FunctionComponent, useEffect, useRef } from "react";

import styles from "./Modal.module.scss";
import { ModalContentType, ModalProps } from "./ModalProps";

export const Modal: FunctionComponent<ModalProps> = ({
  modalHeadline,
  modalText,
  modalBtnSubmit,
  modalBtnClose,
  modalType = null,
  toggleModal,
  submitModal,
  exitBtn = true,
  contentBtn = false,
  dualBtn = false,
  bottomBtn = false,
  bottomBtnLabel = "OK",
  dualBtnCancel,
  dualBtnSubmit,
  submitBtnStyle,
  submitBtnInnerStyle,
  bottomBtnStyle,
  bottomBtnInnerStyle,
  sizeButton = "medium",
  courseNumber,
  courseTotal,
  completedText = "completed",
  storeValueCallback,
  setting,
  linkBottom = false,
  linkBottomLabel
}) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refContainerText = useRef<HTMLDivElement>(null);

  const submitCancel = () => {
    toggleModal(false);
  };

  let runningTimeoutAutofocus: number;

  /**
   * Sets everything except itself to modal, then back on exit/release.
   * Works by flipping tabindex back and forth (or removed on unset).
   */
  function SetModalByContainer(rootContainer: HTMLElement, newState: boolean) {
    let oldTabIndex;
    let currentNode;
    let nodeList: NodeList;
    if (newState) {
      nodeList = rootContainer.querySelectorAll(
        'button, input, [tabindex="0"]'
      ) as NodeList;
    } else {
      nodeList = rootContainer.querySelectorAll("[tabindex_old]") as NodeList;
    }

    for (let i = 0; i < nodeList.length; i++) {
      if (newState) {
        currentNode = nodeList[i] as HTMLElement;
        oldTabIndex = currentNode.getAttribute("tabindex");
        if (oldTabIndex == null) oldTabIndex = "null";
        currentNode.setAttribute("tabindex_old", oldTabIndex);
        currentNode.setAttribute("tabindex", "-1");
      } else {
        currentNode = nodeList[i] as HTMLElement;
        oldTabIndex = currentNode.getAttribute("tabindex_old");
        currentNode.removeAttribute("tabindex_old");
        if (oldTabIndex === "null") {
          currentNode.removeAttribute("tabindex");
        } else if (oldTabIndex != null) {
          currentNode.setAttribute("tabindex", oldTabIndex);
        }
      }
    }
  }

  /**
   * Sets DOM either disabled (true/modal) or enabled (false).
   * Works by affecting tabindex attribute to avoid being able to tab elements below modal itself (screen readers).
   * @param newState True=modal (minus itself), false=no modal.
   */
  function SetModal(newState: boolean) {
    if (newState) {
      if (refContainer.current != undefined) {
        SetModalByContainer(document.body, true);
        SetModalByContainer(refContainer.current, false);
      }
    } else {
      SetModalByContainer(document.body, false);
    }
  }

  /**
   * Local handler for submitting component value down to parent.
   * @param newValue
   */
  function HandleSubmit(newValue: any) {
    console.log("Modal.HandleSubmit: newValue=", newValue);

    //trigger store function?
    if (storeValueCallback != null) storeValueCallback(newValue);

    //trigger prop function
    submitModal();
  }

  /**
   * setTimeout for a delayed function/method call. Clears running.
   * @param delayCallback Function to trigger delayed. No args. Name only (MySuperFunction).
   * @param delayMs Delay in milliseconds.
   * @param clearTimeoutID  Timeout to clear, for instance same variable (number) used initiallyto clear itself and avoid multiple running timeouts.
   */
  function DelayedCallback(
    delayCallback: Function,
    delayMs: number,
    clearTimeoutID: number
  ) {
    let newTimeout = 0;

    if (clearTimeoutID !== null) clearTimeout(clearTimeoutID);
    newTimeout = setTimeout(delayCallback, delayMs);

    return newTimeout;
  }

  /**
   * Sets focus to starting element of active context.
   * By doing this delayed (after render) to an element with tabindex="0" NVDA for instance will start talking DOM-elements within that box.
   * This will require custom adjustments per template.
   */
  function FocusTTS() {
    if (refContainerText.current != null) {
      //console.log("Modal.FocusTTS");
      refContainerText.current.focus({
        preventScroll: true
      });
    }
  }

  /**
   * Cleanup!
   */
  function Release() {
    //console.log("Modal: Release!");
    clearTimeout(runningTimeoutAutofocus);
    SetModal(false);
  }

  function DelayedFocusTTS() {
    //issue delayed focus for first element (nvda)
    clearTimeout(runningTimeoutAutofocus);
    runningTimeoutAutofocus = DelayedCallback(
      FocusTTS,
      200,
      runningTimeoutAutofocus
    );
  }

  /**
   * Only trigger once, and handle GC/release.
   */
  useEffect(() => {
    //issue delayed focus for first element (nvda)
    //DelayedFocusTTS();
    SetModal(true);

    return () => Release();
  }, []);

  return (
    <div className={styles["modal"]}>
      <div className={styles["modalOverlay"]}></div>
      <div
        className={styles["modalContent"]}
        role="dialog"
        aria-modal={true}
        //aria-label={modalHeadline}
        //aria-live="assertive"
        //currently commented out due to double-talking/repeating by nvda
        //set aria-live="assertive" to container to start reading this modal
        //aria-labelledby={'headerText'}
        //described by not reliable
        //aria-describedby={"contentText"}
        //tabIndex={0}
        ref={refContainer}
      >
        <>{DelayedFocusTTS()}</>
        <div className={styles["noFocusVisible"]} tabIndex={-1}>
          <div
            className={styles["modalTextContainer"]}
            ref={refContainerText}
            tabIndex={-1}
          >
            {modalHeadline !== "" && (
              <p id="headerText" className={styles["heading"]}>
                {modalHeadline}
              </p>
            )}
            <p id="contentText" className={styles["text"]}>
              {modalText}
            </p>
          </div>
          {courseNumber !== undefined &&
            courseTotal !== undefined &&
            courseTotal > 1 && (
              <div className={styles["modalStatusNumbers"]}>
                <Text as="p" customClassName={styles["statusNumbers"]}>
                  {courseNumber}/{courseTotal}
                </Text>
                <Text as="p" customClassName="completed-text">
                  {completedText}
                </Text>
              </div>
            )}

          {contentBtn && (
            <div className={styles["modalBtnContainer"]}>
              <Button
                variant="primary"
                style={submitBtnStyle}
                innerStyle={submitBtnInnerStyle}
                size={sizeButton}
                type="submit"
                attribute="modal-submit"
                onClick={submitModal}
              >
                {modalBtnSubmit ? modalBtnSubmit : modalBtnClose}
              </Button>
            </div>
          )}

          {linkBottom && (
            <div className={styles["modalLinkTxt"]}>
              <Text as="p">
                <Link href="#" onClick={submitCancel}>
                  {linkBottomLabel}
                </Link>
              </Text>
            </div>
          )}

          {dualBtn && (
            <div className={styles["modalBtnContainer"]}>
              <Button
                variant="secondary"
                style={submitBtnStyle}
                innerStyle={submitBtnInnerStyle}
                size={sizeButton}
                attribute="modal-cancel"
                onClick={toggleModal}
              >
                {dualBtnCancel}
              </Button>
              <Button
                variant="secondary"
                style={submitBtnStyle}
                innerStyle={submitBtnInnerStyle}
                size={sizeButton}
                type="submit"
                attribute="modal-submit"
                onClick={submitModal}
              >
                {dualBtnSubmit}
              </Button>
            </div>
          )}

          {modalType === ModalContentType.NUMBER && (
            <Rating
              numbersOfRating={setting.modalRating.numbersOfRating}
              // instructionText="Test"
              submitLabel={
                setting.modalRating.submitLabel
                  ? setting.modalRating.submitLabel
                  : setting.defaultVariables.defaultSend
              }
              submitRate={HandleSubmit}
            />
          )}

          {modalType === ModalContentType.TEXTINPUT && (
            <FreeTextInput
              textareaLabel={setting.modalFreeTextInput.textareaLabel}
              instructionText={setting.modalFreeTextInput.instructionText}
              textareaRemainText={setting.modalFreeTextInput.textareaRemainText}
              textareaTooMuchText={
                setting.modalFreeTextInput.textareaTooMuchText
              }
              maxNumberCharacters={
                setting.modalFreeTextInput.maxNumberCharacters
              }
              submitLabel={
                setting.modalFreeTextInput.submitLabel
                  ? setting.modalFreeTextInput.submitLabel
                  : setting.defaultVariables.defaultSend
              }
              submitInput={HandleSubmit}
            />
          )}
        </div>
        {exitBtn && (
          <div className={styles["modalExitContainer"]}>
            <button
              className={styles["modalExitBtn"]}
              onClick={submitCancel}
              aria-label={modalBtnClose}
              data-type="exit-icon"
            >
              <IconClose />
            </button>
          </div>
        )}
      </div>
      {bottomBtn && (
        <div className={styles["modalBottomContainer"]}>
          <Button
            style={bottomBtnStyle}
            innerStyle={bottomBtnInnerStyle}
            size={sizeButton}
            attribute="modal-cancel"
            onClick={submitCancel}
          >
            {bottomBtnLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Modal;
