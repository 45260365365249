/**
 * Valid course statuses.
 */
export enum CourseStatus {
  COMPLETED = "completed",
  INCOMPLETE = "incomplete",
  NOTATTEMPTED = "notattempted",
  LOCKED = "locked",
  PASSED = "passed"
}

/**
 * Valid unlock course methods to send in.
 */
export enum UnlockCourseMethod {
  UNLOCK_ALL_AFTER_FIRST_COMPLETED = "unlock_all_after_firs_completed",
  UNLOCK_ONE_AT_A_TIME = "unlock_one_at_a_time"
}

/**
 * Valid course data attempts - data we get from server response, mapped to this type.
 */
export interface TypeCourseDataAttempts {
  courseId: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  result: string;
  status: string;
  totalTime: number;
  userId: string;
  data: any;
}

export interface ScheduleEmailsObject {
  scheduleEmailInfo: Array<EmailInfo>;
  mentorScheduleEmailInfo?: Array<EmailInfo>;
}

export interface EmailInfo {
  months: number;
  typeOfEmail: string;
}

export interface LearningpathUserStatus {
  id: string;
  learningpath_status: LearningpathStatus;
  meta: {
    courses: Array<Courses>;
  };
  title: string;
  type: string;
}

export interface LearningpathStatus {
  date: string;
  displayedToUser: boolean;
  status: string;
}

export interface Courses {
  content: object;
  course_status?: {
    status: CourseStatus;
    date: string;
  };
  description: string;
  id: string;
  launch: {
    url: string;
  };
  locale: string;
  meta: object;
  tags: Array<any>;
  title: string;
  versions?: Array<Courses>;
}
/**
 *
 */
export interface TypeCourseDataFeedback {
  /** Server/portal zero-based course index for this specific feedback. */
  id: number;

  /** Text/question. */
  text: string;

  /** Type of value (number, string). */
  type: string;

  /** User input for that specific feedback. Blank by default. */
  userAnswer: string;
}

/**
 * suspend_data "m" / module status.
 */
/*export class TypeCourseDataStatusModule {
  id: string;
  target: string;
  status: CourseStatus;
}*/

/**
 * suspend_data "i" / item status.
 */
/*export class TypeCourseDataStatusItem {
  id: string;
  status: string;
}*/

/**
 * suspend_data needs to be decompressed first, then parsed.
 * There are two objects: m[] (module status as a whole) and i[] (item status).
 */
/*export interface TypeCourseDataSuspendData {
  
  //Module status per module. Used by course menu to reflect individual module status per "button".
  statusModules: Array<TypeCourseDataStatusModule>;
  
  //Individual item statuses.
  statusItems: Array<TypeCourseDataStatusItem>;
}*/

/**
 * Valid course data - data we get from server response, mapped to this class, and some more for cacheing.
 */
export class TypeCourseData {
  courseId: string;
  attempts: Array<TypeCourseDataAttempts>;
  /**
   * This is normally undefined until first attempt is kicked into gear and commits start comming in.
   * This can then be used to couple feedback onto that same attempt ID.
   * */
  mostRecentAttemptID: string;
  mostRecentSuspendData: object;
  completedDate: string;
  lastUpdated: string;
  type: string;
  title: string;
  launchURL: string;
  description: string;
  locale: string;
  link: string;
  status: CourseStatus;
  feedback: TypeCourseDataFeedback[];
  courseCompleted: number;
  courseTotal: number;
  currentGoToItemId: string;

  /**
   * For repetition modules we need to define unlock millis during parse (3 months since learning path was completed etc).
   * We can assume a pending/open repetition or "special" module has this set, and everything else has this 0.
   * This starts off as an offset, then gets calculated into actual time for direct testing.
   * */
  unlockTimeMilliseconds: number;

  /**
   * Hide from render/menu.
   * This might for instance be a repetition module for which primary learning path has no COMPLETE state yet.
   */
  hidden: boolean;
}
